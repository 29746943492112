import React, { useEffect, useState } from 'react';
import JSONData from "../data/texts.json"
import AppLayout from "../components/layouts/app"
import agent from "../lib/agent"
import { Link } from 'gatsby'

const ResetPassword = () => {
  const [token, setToken] = useState("");
  const [formClass, setFormClass] = useState("d-none");

  let [user, setUser] = useState({
    email: '',
    password: '',
    confirm_password: '',
    user_id: ''
  });

  useEffect(() => {
    const t = window.location.href.split("?")[1];
    agent.Password.resetToken(t)
      .then(res => {
        console.log(res)
        const u = {
          email: res.data.email,
          user_id: res.data.id
        }
        setUser(u)
        setFormClass("")
        setToken(t)
      })
      .catch(err => {
        setFormClass("")
        console.log("err", err)
        //const msg = JSON.parse(err.response.text)
      })
      .finally(() => console.log("ok"))
  }, []); // Only re-run the effect if count changes


  const handleChange = (e) => {
    console.log(e)
    const name = e.target.name;
    const value = e.target.value;
    const u = {...user}
    u[name] = value;
    setUser(u);
  }

  const submit = (e) => {
    e.preventDefault();
    console.log(e);
    agent.Password.reset(token, user)
    .then(res => {
      // Log in and redirect to app
      var tokenString = "";
      if (res.token) {
        tokenString = JSON.stringify(res.token)
      }
      localStorage.setItem("jwt", tokenString)
      window.location.href = "/app";
    })
  }

  const page = {
    title: "Reset password",
    description: ""
  }

  console.log(user.password)
  return (
    <main >
      <title>{page.title}</title>
      <AppLayout menu={JSONData.menu} page={page}>
        <div className="welcome-area" id="welcome">
          <div className="form-card-container app-form col-10 col-md-8 col-l-6">
            <h3 className="form-card-header">Reset your password</h3>
            <div className="form-card-content">
              <div className="col-lg-12">
                <div className="form-card-form">
                  <div className="notice d-none">
                    <p className="text-muted text-center mb-3">The token has expired, please create a new password reset token from this
                      <Link to="/forgot-password">link</Link>
                    </p>
                  </div>
                  <div className="error d-none p-3">
                    <p className="text-danger text-center mb-3">
                      The token has expired, please create a new password reset token from this
                      <Link to="/forgot-password">link</Link>
                    </p>
                  </div>
                  <form id="reset-password-form" className={formClass} action="" onSubmit={submit}>
                    <div className="col-lg-12 col-md-12 col-sm-12">
                      <fieldset>
                        <label htmlFor="email">Email</label>
                        <input id="email" type="email" className="form-control email" name="email" value={user.email} onChange={handleChange} required autofocus disabled />
                      </fieldset>
                    </div>

                    <div className="col-lg-12 col-md-12 col-sm-12">
                      <fieldset>
                        <label htmlFor="password">New Password</label>
                        <input id="password" type="password" className="form-control pwstrength" name="password" value={user.password} onChange={handleChange} required />
                        <div id="pwindicator" className="pwindicator">
                          <div className="bar"></div>
                          <div className="label"></div>
                        </div>
                      </fieldset>
                    </div>

                    <div className="col-lg-12 col-md-12 col-sm-12">
                      <fieldset>
                        <label htmlFor="confirm-password">Confirm Password</label>
                        <input id="confirm-password" type="password" className="form-control" name="confirm_password" value={user.confirm_password} onChange={handleChange} required />
                      </fieldset>
                    </div>

                    <div className="col-lg-12">
                      <fieldset>
                        <button type="submit" className="form-submit main-button col-lg-12" aria-label="Submit Reset Password">
                            <span className="hidden spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                            Submit
                        </button>
                      </fieldset>
                    </div>
                  </form>
                </div>
            </div>
          </div>
        </div>
        </div>
      </AppLayout>
    </main>
  )
}

export default ResetPassword
